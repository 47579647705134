<template>
  <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="white"
      opacity="0.85"
      spinner-variant="primary"
  >
    <b-container class="d-flex flex-wrap justify-content-start">
      <div
          class="w-100 tests-category"
          v-for="category in categories"
          :key="category.id"
      >
        <h2 class="col-12 text-primary">{{ category | categoryIdToTitle }}</h2>
        <div class="d-flex col-12 flex-wrap p-0">
          <div
              class="cols-12 col-md-5 flex-wrap d-flex flex-column align-items-start"
              v-for="test in tests.filter((item) => item.category === category)"
              :key="test.id"
          >
            <div
                class="test-card"
                type="button"
                @click="getTestQuestions(test.uuid)"
                :style="{backgroundImage: 'url(' + test.image + ')' }"
            >
              <div class="test-card-header d-flex justify-content-between">
                <h3
                    class="m-0"
                    v-if="test.description.length !== 0"
                >
                  {{test.title}}
                </h3>
                <TestDone
                    v-if="test.done"
                    class="test-card__done"
                />
              </div>
              <div class="d-flex justify-content-between align-items-end">
                <p
                    v-if="test.description.length !== 0"
                    class="m-0"
                >
                  {{test.description}}
                </p>
                <h3 v-else>{{test.title}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import { getTests } from '../api/profi_club/tests';
import TestDone from '../assets/testDone.svg';

export default {
  components: {
    TestDone,
  },
  name: 'Tests',
  data() {
    return {
      tests: [],
      isLoading: true,
    };
  },
  methods: {
    async getTestQuestions(id) {
      await this.$router.push(`/tests/${id}`);
    },
  },
  computed: {
    categories() {
      const categories = this.tests.map((item) => item.category);
      return new Set(categories);
    },
  },
  async created() {
    const testsResult = await getTests();
    this.tests = testsResult.list;
    this.isLoading = false;
  },
};
</script>

<style scoped lang="scss">

.test-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(255,255,255,0.3),rgba(0,0,0,1));
  width: 100%;
  border-radius: 5px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
.test-card:hover {
  &::before {
    opacity: 0.5;
  }
}

.test-card > * {
  position: relative;
}
.tests-category {
  margin: 50px 0 50px 0;
  .test-card{
    overflow: hidden;
    box-sizing: border-box;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    min-height: 153px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .test-card-header{
      top: 10px;
      align-items: center;
      justify-content: space-between;
    }
  }
}
@media (max-width: 768px) {
  .test-card {
    margin-bottom: 10px;
  }
}
</style>
